.App {
  text-align: center;
}
.ReactModal__Body--open { overflow: hidden; }

html {
  height: 100%;
  font-family: 'Open Sans', sans-serif;
}
body, #root, .app {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #FFF;
}

.app {
  position: relative;
  right: 0;
  transition: right 0.5s;
}
.app.stop {
  overflow: hidden;
  right: 400px;
}

.app.stop > div {
  overflow: hidden;
}

form {
  padding: 20px;
  margin-top: 60px;
}
form button, form h5 {
  margin: 20px 0;
}

form button {
  background-color: #58BE8D;
}

input[type=text]:not(.browser-default):focus:not([readonly]),
input[type=email]:not(.browser-default):focus:not([readonly]),
input[type=password]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]){
  border-color: #000;
}

input[type=text]:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label,
input[type=email]:not(.browser-default):focus:not([readonly]) + label,
input[type=password]:not(.browser-default):focus:not([readonly]) + label{
  color: #000 !important;
}

h1 {
  color: black;
  font-size: 5em;
}

.loading {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FBFBFB;
  color: #16121C;
}
