.post_container {
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.post_table {
    width: 100%;
    max-width: 1400px;
    border-spacing: 0;
}
.post_table .header_image_row td {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: 0;
    padding: 0;
    border: none;
    
}
.post_table .header_image_row td img {
    height: auto;
    width: 100%;
    max-width: 100%;
}
.post_table .gallery_image_row td img {
    height: auto;
    width: 100%;
    max-width: 100%;
}
.post_table .meta_row td {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.post_table .meta_row td .title {
    padding-bottom: 5px;
    font-size: 3.5em;
    color: #16121C;
    font-weight: 900;
    line-height: 1em;
    margin: 0;
}
.post_table .meta_row td .subtitle {
    font-size: 1.75em;
    color: #708094;
    line-height: 1em;
    margin: 0;
    padding-bottom: 15px;
}
.post_table .meta_row td .date {
    font-size: 1em;
    color: #555;
}
.post_table .body p, .post_table .body q {
    line-height: 1.5em;
    margin: 0;
}
.post_table .body q {
    font-style: italic;
}
.post_table .body {
    padding: 50px 0;
}
.post_table .body ul {
    margin-top: -5px;
    margin-left: -30px;
    list-style-type: square;
}
.post_table .body ul li {
    margin: 12px;
}
.post_table .body a {
    color: #5f8cc4;
    font-weight: 600;
}
.share_section {
    text-align: center;
    padding: 1em 0;
    margin-bottom: 50px;
}
.share_section h3 {
    font-size: 24px;
    margin: 20px 0;
    margin-top: 10px;
    font-weight: 900;
    color: #16121C;
}
.share_section p {
    margin-top: 30px;
    font-size: 14px;
    color: #8A8993;
    font-size: 12px;
}
.st-btn {
    background: #000 !important;
    border: none !important;
    border-radius: 50% !important;
    height: 30px !important;
    width: 30px !important;
    line-height: 40px !important;
}
.st-btn img {
    filter: brightness(0) invert(1);
}
@media only screen and (max-width: 800px) {
    .post_table .meta_row td {
        text-align: center;
    }
    .post_table .meta_row td .title {
        font-size: 2em;
    }
    .post_table .meta_row td .subtitle {
        font-size: 1em;
    }
    .post_table .meta_row td .date {
        font-size: 0.75em;
    }
}
@media only screen and (max-width: 600px) {
    .post_table .header_row td .float_left .SIFI_desc {
        display: none;
    }
}
.dashboard_posts {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.dashboard_post {
    display: inline-block;
    width: 90%;
    max-width: 500px;
    margin: 10px 0;
    padding: 5px;
    padding-left: 15px;
    background-color: #fff;
    color: #16121C;
    text-align: left;
    border-radius: 5px;
    border: 1px solid #D8D8D8;
    box-shadow: 0 10px 10px rgba(0,0,0,0.05);
}
.dashboard_post_meta {
    display: inline-block;
}
.dashboard_post_title {
    margin: 0;
    margin-top: 5px;
    font-size: 20px;
    font-weight: bold;
}
.dashboard_post_subtitle {
    font-size: 12px;
    color: #708094;
    font-weight: 600;
}
.dashboard_post_id {
    font-size: 10px;
    color: #000;
}
.dashboard_post_date {
    color: #BBB;
    font-size: 10px;
}
.dashboard_post_links {
    display: inline-block;
    float: right;
}
.dashboard_post_link {
    margin: 10px;
    display: inline-block;
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 5px;
    color: #FFF;
    width: fit-content;
    font-size: 12px;
    letter-spacing: 2px;
    font-weight: 900;
    box-shadow: 0 0 10px rgba(0,0,0,0.15);
}
#view_link {
    background-color: #58BE8D;
    border-radius: 5px;
    border: 1px solid #000;
    box-shadow: 0 10px 10px rgba(0,0,0,0.05);
}
#edit_link {
    background-color: #EBCF43;
    border-radius: 5px;
    border: 1px solid #000;
    box-shadow: 0 10px 10px rgba(0,0,0,0.05);
}
#delete_link {
    background-color: #EB5F43;
    border-radius: 5px;
    border: 1px solid #000;
    box-shadow: 0 10px 10px rgba(0,0,0,0.05);
}
.dashboard_post_link {
    margin: 10px;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
    color: #FFF;
    width: fit-content;
    font-size: 12px;
    letter-spacing: 2px;
    font-weight: 900;
    box-shadow: 0 0 10px rgba(0,0,0,0.15);
}
.input-checkbox {
    display: block;  
    margin-top: 30px;
    margin-bottom: 50px;
    z-index: 999;
}
.dashboard_checkbox {
    opacity: 1 !important;
    padding: 1em;
    height: 20px;
    width: 20px;
    position: relative;
    z-index: 999;
    cursor: pointer;
    pointer-events: all !important;
}
.dashboard_checkbox:focus {
    background-color: #58BE8D;
}
#published {
    position: relative;
    display: inline;
    line-height: 20px;
    margin-block-start: 0;
    margin-block-end: 0;
}
.dashboard_checkbox:checked {
    background: #58BE8D;
}