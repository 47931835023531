.nav {
    height: 100px;
    background-color: #FFF;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
.nav_inner {
    width: 100%;
    max-width: 1400px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav_inner .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    transition: all 0.25s;
}

.nav_inner .logo .icon {
    position: relative;
    bottom: 4px;
    transition: all 0.25s;
}
.nav_inner a {
    text-decoration: none;
}
.nav_inner h1 {
    font-weight: 900;
    font-size: 2em;
    letter-spacing: -1px;
    line-height: 0.9em;
    white-space: nowrap;
    margin: 0;
    margin-left: 10px;
    transition: all 0.25s;
    
}
.nav_inner .page_links {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
}
.nav_inner .page_links a {
    color: #000;
    text-decoration: none;
    font-weight: 600;
    padding: 1em;
}
.nav_inner .social_links {
    display: flex;
}
.nav_inner .social_links div {
    background: #000;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin: 5px;
    display: flex;
    justify-content: center;
}

.nav_inner .social_links div a {
    border-radius: 50%;
    width: 100%;
    text-decoration: none;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7em;
    font-weight: 600;
}
.nav_inner .burger {
    height: 100px;
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: none;
    position: relative;
    left: 20px;
}
.nav_inner .burger button { 
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
}
.nav_inner .burger div { 
    height: 3px;
    width: 40px;
    background: #000;
    margin: 3px;
    transition: all 0.5s;
    position: relative;
    left: 25px;
}
.nav_wrapper .burger.open div:nth-child(1) {
    transform: rotate(45deg);
    top: 5px;
}
.nav_wrapper .burger.open div:nth-child(2) {
    width: 0;
}
.nav_wrapper .burger.open div:nth-child(3) {
    transform: rotate(-45deg);
    bottom: 6px;
}

.nav_wrapper .menu {
    height: 0px;
    background: #000;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    transition: all 0.5s;
}
.nav_wrapper .menu .page_links {
    display: flex;
    flex-direction: column;
    display: none;
}
.nav_wrapper .menu .page_links a {
    text-decoration: none;
    font-weight: 600;
    color: #FFF;
    padding: 1em;
    font-size: 1.5em;
}
.nav_wrapper .menu.open .page_links {
    display: flex;
    justify-content: center;
    align-items: center;
}
.nav_wrapper .menu .social_links {
    justify-content: space-evenly;
    display: none;
}
.nav_wrapper .menu.open .social_links {
    display: flex;
}
.nav_wrapper .menu .social_links div {
    background: #FFF;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.nav_wrapper .menu.open {
    height: 400px;
    transition: all 0.5s;
}

@media only screen and (max-width: 750px) {
    .nav_inner .burger {
        display: flex;
    }
    .nav_inner .page_links {
       display: none;
    }
    .nav_inner .social_links {
        display: none;
    }
    .nav_inner .logo {
        position: relative;
        right: 7px;
    }
    .nav_inner .logo .icon {
        transform: scale(0.75)
     }
     .nav_inner .logo h1 {
        font-size: 1.5em;
        margin-left: 0px;
     }
}