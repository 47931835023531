footer {
    color: #8A8993;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding: 20px 0;
}

footer .to_top {
    background: #000;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}