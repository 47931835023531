.create_post {
    display: flex;
    justify-content: center;
    align-items: center;
}

.create_post form {
    border-radius: 5px;
    border: 1px solid #000;
    box-shadow: 0 10px 10px rgba(0,0,0,0.05);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FFF;
}
.create_post form h5 {
    font-size: 30px;
}

.create_post form .input_field {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.create_post .quill {
    background-color: #FFF;
    border-radius: 5px;
    border: 1px solid #000;
    overflow: hidden;
    box-shadow: 0 10px 10px rgba(0,0,0,0.05);
}

.create_post form .input_field input {
    width: 200px;
    margin: 10px 0px;
    font-size: 20px;
    padding: 10px 5px;
    border-radius: 5px;
    border: 1px solid #000;
    box-shadow: 0 10px 10px rgba(0,0,0,0.05);
}
.create_post form .input_field button {
    width: 210px;
    font-size: 20px;
    padding: 10px 5px;
    border-radius: 5px;
    background-color: #708094;
    border: 1px solid #000;
    box-shadow: 0 10px 10px rgba(0,0,0,0.05);
    font-weight: 900;
    color: #FFF;
}