.home_wrapper {
    background-color: #FFF;
    width: 100%;
    position: relative;
    text-align: center;
}
.home_wrapper .posts_wrapper {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 1;
}
.home_posts {
    width: 100%;
    max-width: 1400px;
    display: inline-block;
    vertical-align: top;
    text-align: left;
}
.home_post {
    float: left;
    margin: 0;
    width: 25%;
    padding-bottom: 20%;
    color: #16121C;
    position: relative;
    text-align: center;
    /* box-shadow: 0 10px 10px rgba(0,0,0,0.05); */
}
.home_post_image {
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
}
/* .home_post:nth-child(1) {
    border-bottom: 1px solid red;
}
.home_post:nth-child(3) {
    border-top: 1px solid red;
} */
.home_post_info {
    opacity: 0;
    background: rgba(255,255,255,0.75);
    position: absolute;
    top: 0;
    left: 0;
    vertical-align: top;
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 0.25s;
}
.home_post_info:hover { 
    opacity: 1;
    transition: all 0.25s;
}
.home_post_image img {
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
}
.home_post_title {
    font-weight: 900;
    font-size: 1.75em;
    position: relative;
    bottom: 10px;
}
.home_post_subtitle {
    font-size: 0.75em;
    position: relative;
    bottom: 35px;
    color: #708094;
    font-weight: 600;
}
.home_post_date {
    font-size: 12px;
    color: #8F8F8F;
    position: relative;
    top: 20px;
}

@media only screen and (max-width: 750px) {
    .home_header_inner .burger {
        display: flex;
    }
    .home_post {
        width: 50%;
        padding-bottom: 40%;
    }
    .home_header_inner .page_links {
       display: none;
    }
    .home_header_inner .social_links {
        display: none;
    }
    .home_header_inner .logo {
        position: relative;
        right: 7px;
    }
    .home_header_inner .logo .icon {
        transform: scale(0.75)
     }
     .home_header_inner .logo h1 {
        font-size: 1.5em;
        margin-left: 0px;
     }
}

@media only screen and (max-width: 500px) {
    .home_post {
        width: 100%;
        padding-bottom: 75%;
    }
    .home_post_title {
        font-size: 1.5em;
    }
    .home_post_subtitle {
        font-size: 0.75em;
        bottom: 30px;
    }
    .home_post_date {
        font-size: 0.75em;
        top: 15px;
    }
}