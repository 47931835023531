.dashboard_container {
    display: block;
    min-height: 100vh;
    width: 100%;
    margin: 0;
    padding-top: 75px;
    background-color: #F5F8F9;
}
.dashboard_container .nav_links {
    display: flex;
    position: absolute;
    width: 100%;
    z-index: 0;
    top: 0;
    height: 105px;
    background-color: #FFF;
}
.dashboard_container .react-tabs {
    position: relative;
    z-index: 1;
}

.dashboard_container .react-tabs__tab-list {
    border-bottom: 1px solid #000;
}

.dashboard_container .react-tabs__tab--selected {
    background-color: #F5F8F9;
    border-color: #000;
}
.dashboard_container .nav_links > div {
    background-color: #F5F8F9;
    height: 50px;
    width: 50px;
    border-radius: 5px;
    border: 1px solid #000;
    box-shadow: 0 10px 10px rgba(0,0,0,0.05);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    margin-right: 0;
}

.dashboard_container .nav_links .signout_btn {
    background: none;
    outline: none;
    border: none;
    height: 50px;
    width: 50px;
}


.notifications_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}
.notifications {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.notifications li {
    list-style: none;
    border-radius: 5px;
    width: 250px;
    background-color: #FFF;
    margin: 10px;
    padding: 14px;
    border: 1px solid #000;
    box-shadow: 0 10px 10px rgba(0,0,0,0.05);
}
.notifications .date, .subscribers .date {
    color: #708094;
}

.subscribers {
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
}

.subscribers li {
    list-style: none;
    border-radius: 5px;
    width: 250px;
    background-color: #FFF;
    margin: 10px;
    padding: 15px;
    border: 1px solid #000;
    box-shadow: 0 10px 10px rgba(0,0,0,0.05);
}

.tab_number {
    color: #708094;
    font-size: 12px;
    font-weight: 900;
    position: relative;
    bottom: 5px;
    border-radius: 50%;
}

.delete_post_popup {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1000;
    padding: 1em;
    height: fit-content;
    width: fit-content;
    background-color: #FFF;
    border: 2px solid red;
    border-radius: 5px;
    box-shadow: 0 0 100px rgba(0,0,0,0.15)
}

.delete_post_btn {
    margin: 10px;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
    color: #FFF;
    width: fit-content;
    font-size: 12px;
    letter-spacing: 2px;
    font-weight: 900;
    box-shadow: 0 0 10px rgba(0,0,0,0.15);
}

.delete_post_btn.yes {
    background-color: #58BE8D;
}
.delete_post_btn.no {
    background-color: #EB5F43;
}