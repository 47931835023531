body, #root, #root > div {
  margin: 0;
  width: 100%;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  color: black;
  font-size: 5em;
}

.App {
  text-align: center;
}
.ReactModal__Body--open { overflow: hidden; }

html {
  height: 100%;
  font-family: 'Open Sans', sans-serif;
}
body, #root, .app {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #FFF;
}

.app {
  position: relative;
  right: 0;
  -webkit-transition: right 0.5s;
  transition: right 0.5s;
}
.app.stop {
  overflow: hidden;
  right: 400px;
}

.app.stop > div {
  overflow: hidden;
}

form {
  padding: 20px;
  margin-top: 60px;
}
form button, form h5 {
  margin: 20px 0;
}

form button {
  background-color: #58BE8D;
}

input[type=text]:not(.browser-default):focus:not([readonly]),
input[type=email]:not(.browser-default):focus:not([readonly]),
input[type=password]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]){
  border-color: #000;
}

input[type=text]:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label,
input[type=email]:not(.browser-default):focus:not([readonly]) + label,
input[type=password]:not(.browser-default):focus:not([readonly]) + label{
  color: #000 !important;
}

h1 {
  color: black;
  font-size: 5em;
}

.loading {
  height: 100vh;
  width: 100vw;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: #FBFBFB;
  color: #16121C;
}

.home_wrapper {
    background-color: #FFF;
    width: 100%;
    position: relative;
    text-align: center;
}
.home_wrapper .posts_wrapper {
    width: 100%;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    z-index: 1;
}
.home_posts {
    width: 100%;
    max-width: 1400px;
    display: inline-block;
    vertical-align: top;
    text-align: left;
}
.home_post {
    float: left;
    margin: 0;
    width: 25%;
    padding-bottom: 20%;
    color: #16121C;
    position: relative;
    text-align: center;
    /* box-shadow: 0 10px 10px rgba(0,0,0,0.05); */
}
.home_post_image {
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
}
/* .home_post:nth-child(1) {
    border-bottom: 1px solid red;
}
.home_post:nth-child(3) {
    border-top: 1px solid red;
} */
.home_post_info {
    opacity: 0;
    background: rgba(255,255,255,0.75);
    position: absolute;
    top: 0;
    left: 0;
    vertical-align: top;
    height: 100%;
    width: 100%;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
}
.home_post_info:hover { 
    opacity: 1;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
}
.home_post_image img {
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
}
.home_post_title {
    font-weight: 900;
    font-size: 1.75em;
    position: relative;
    bottom: 10px;
}
.home_post_subtitle {
    font-size: 0.75em;
    position: relative;
    bottom: 35px;
    color: #708094;
    font-weight: 600;
}
.home_post_date {
    font-size: 12px;
    color: #8F8F8F;
    position: relative;
    top: 20px;
}

@media only screen and (max-width: 750px) {
    .home_header_inner .burger {
        display: -webkit-flex;
        display: flex;
    }
    .home_post {
        width: 50%;
        padding-bottom: 40%;
    }
    .home_header_inner .page_links {
       display: none;
    }
    .home_header_inner .social_links {
        display: none;
    }
    .home_header_inner .logo {
        position: relative;
        right: 7px;
    }
    .home_header_inner .logo .icon {
        -webkit-transform: scale(0.75);
                transform: scale(0.75)
     }
     .home_header_inner .logo h1 {
        font-size: 1.5em;
        margin-left: 0px;
     }
}

@media only screen and (max-width: 500px) {
    .home_post {
        width: 100%;
        padding-bottom: 75%;
    }
    .home_post_title {
        font-size: 1.5em;
    }
    .home_post_subtitle {
        font-size: 0.75em;
        bottom: 30px;
    }
    .home_post_date {
        font-size: 0.75em;
        top: 15px;
    }
}
footer {
    color: #8A8993;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    font-size: 12px;
    padding: 20px 0;
}

footer .to_top {
    background: #000;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    border: none;
    outline: none;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
}
.nav {
    height: 100px;
    background-color: #FFF;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    z-index: 10;
}
.nav_inner {
    width: 100%;
    max-width: 1400px;
    padding: 0 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.nav_inner .logo {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: left;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
}

.nav_inner .logo .icon {
    position: relative;
    bottom: 4px;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
}
.nav_inner a {
    text-decoration: none;
}
.nav_inner h1 {
    font-weight: 900;
    font-size: 2em;
    letter-spacing: -1px;
    line-height: 0.9em;
    white-space: nowrap;
    margin: 0;
    margin-left: 10px;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
    
}
.nav_inner .page_links {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}
.nav_inner .page_links a {
    color: #000;
    text-decoration: none;
    font-weight: 600;
    padding: 1em;
}
.nav_inner .social_links {
    display: -webkit-flex;
    display: flex;
}
.nav_inner .social_links div {
    background: #000;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.nav_inner .social_links div a {
    border-radius: 50%;
    width: 100%;
    text-decoration: none;
    color: #FFF;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    font-size: 0.7em;
    font-weight: 600;
}
.nav_inner .burger {
    height: 100px;
    width: 100px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    display: none;
    position: relative;
    left: 20px;
}
.nav_inner .burger button { 
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
}
.nav_inner .burger div { 
    height: 3px;
    width: 40px;
    background: #000;
    margin: 3px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    position: relative;
    left: 25px;
}
.nav_wrapper .burger.open div:nth-child(1) {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    top: 5px;
}
.nav_wrapper .burger.open div:nth-child(2) {
    width: 0;
}
.nav_wrapper .burger.open div:nth-child(3) {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    bottom: 6px;
}

.nav_wrapper .menu {
    height: 0px;
    background: #000;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}
.nav_wrapper .menu .page_links {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    display: none;
}
.nav_wrapper .menu .page_links a {
    text-decoration: none;
    font-weight: 600;
    color: #FFF;
    padding: 1em;
    font-size: 1.5em;
}
.nav_wrapper .menu.open .page_links {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.nav_wrapper .menu .social_links {
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    display: none;
}
.nav_wrapper .menu.open .social_links {
    display: -webkit-flex;
    display: flex;
}
.nav_wrapper .menu .social_links div {
    background: #FFF;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.nav_wrapper .menu.open {
    height: 400px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

@media only screen and (max-width: 750px) {
    .nav_inner .burger {
        display: -webkit-flex;
        display: flex;
    }
    .nav_inner .page_links {
       display: none;
    }
    .nav_inner .social_links {
        display: none;
    }
    .nav_inner .logo {
        position: relative;
        right: 7px;
    }
    .nav_inner .logo .icon {
        -webkit-transform: scale(0.75);
                transform: scale(0.75)
     }
     .nav_inner .logo h1 {
        font-size: 1.5em;
        margin-left: 0px;
     }
}
.create_post {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.create_post form {
    border-radius: 5px;
    border: 1px solid #000;
    box-shadow: 0 10px 10px rgba(0,0,0,0.05);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: #FFF;
}
.create_post form h5 {
    font-size: 30px;
}

.create_post form .input_field {
    width: 300px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}
.create_post .quill {
    background-color: #FFF;
    border-radius: 5px;
    border: 1px solid #000;
    overflow: hidden;
    box-shadow: 0 10px 10px rgba(0,0,0,0.05);
}

.create_post form .input_field input {
    width: 200px;
    margin: 10px 0px;
    font-size: 20px;
    padding: 10px 5px;
    border-radius: 5px;
    border: 1px solid #000;
    box-shadow: 0 10px 10px rgba(0,0,0,0.05);
}
.create_post form .input_field button {
    width: 210px;
    font-size: 20px;
    padding: 10px 5px;
    border-radius: 5px;
    background-color: #708094;
    border: 1px solid #000;
    box-shadow: 0 10px 10px rgba(0,0,0,0.05);
    font-weight: 900;
    color: #FFF;
}
.edit_post {

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.edit_post form {
    border-radius: 5px;
    border: 1px solid #000;
    box-shadow: 0 10px 10px rgba(0,0,0,0.05);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: #FFF;
}
.edit_post form h5 {
    font-size: 30px;
}

.edit_post form .input_field {
    width: 300px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}
.edit_post .quill {
    background-color: #FFF;
    border-radius: 5px;
    border: 1px solid #000;
    overflow: hidden;
    box-shadow: 0 10px 10px rgba(0,0,0,0.05);
}

.edit_post form .input_field input {
    width: 200px;
    margin: 10px 0px;
    font-size: 20px;
    padding: 10px 5px;
    border-radius: 5px;
    border: 1px solid #000;
    box-shadow: 0 10px 10px rgba(0,0,0,0.05);
}
.edit_post form .input_field button {
    width: 210px;
    font-size: 20px;
    padding: 10px 5px;
    border-radius: 5px;
    border: 1px solid #000;
    background-color: #708094;
    box-shadow: 0 10px 10px rgba(0,0,0,0.05);
    font-weight: 900;
    color: #FFF;
}
.dashboard_container {
    display: block;
    min-height: 100vh;
    width: 100%;
    margin: 0;
    padding-top: 75px;
    background-color: #F5F8F9;
}
.dashboard_container .nav_links {
    display: -webkit-flex;
    display: flex;
    position: absolute;
    width: 100%;
    z-index: 0;
    top: 0;
    height: 105px;
    background-color: #FFF;
}
.dashboard_container .react-tabs {
    position: relative;
    z-index: 1;
}

.dashboard_container .react-tabs__tab-list {
    border-bottom: 1px solid #000;
}

.dashboard_container .react-tabs__tab--selected {
    background-color: #F5F8F9;
    border-color: #000;
}
.dashboard_container .nav_links > div {
    background-color: #F5F8F9;
    height: 50px;
    width: 50px;
    border-radius: 5px;
    border: 1px solid #000;
    box-shadow: 0 10px 10px rgba(0,0,0,0.05);
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin: 10px;
    margin-right: 0;
}

.dashboard_container .nav_links .signout_btn {
    background: none;
    outline: none;
    border: none;
    height: 50px;
    width: 50px;
}


.notifications_wrapper {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
.notifications {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}

.notifications li {
    list-style: none;
    border-radius: 5px;
    width: 250px;
    background-color: #FFF;
    margin: 10px;
    padding: 14px;
    border: 1px solid #000;
    box-shadow: 0 10px 10px rgba(0,0,0,0.05);
}
.notifications .date, .subscribers .date {
    color: #708094;
}

.subscribers {
   display: -webkit-flex;
   display: flex;
   -webkit-flex-direction: column;
           flex-direction: column;
   -webkit-justify-content: flex-start;
           justify-content: flex-start;
   -webkit-align-items: center;
           align-items: center;
}

.subscribers li {
    list-style: none;
    border-radius: 5px;
    width: 250px;
    background-color: #FFF;
    margin: 10px;
    padding: 15px;
    border: 1px solid #000;
    box-shadow: 0 10px 10px rgba(0,0,0,0.05);
}

.tab_number {
    color: #708094;
    font-size: 12px;
    font-weight: 900;
    position: relative;
    bottom: 5px;
    border-radius: 50%;
}

.delete_post_popup {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1000;
    padding: 1em;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #FFF;
    border: 2px solid red;
    border-radius: 5px;
    box-shadow: 0 0 100px rgba(0,0,0,0.15)
}

.delete_post_btn {
    margin: 10px;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
    color: #FFF;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 12px;
    letter-spacing: 2px;
    font-weight: 900;
    box-shadow: 0 0 10px rgba(0,0,0,0.15);
}

.delete_post_btn.yes {
    background-color: #58BE8D;
}
.delete_post_btn.no {
    background-color: #EB5F43;
}

.post_container {
    background-color: #FFF;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
}
.post_table {
    width: 100%;
    max-width: 1400px;
    border-spacing: 0;
}
.post_table .header_image_row td {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    margin: 0;
    padding: 0;
    border: none;
    
}
.post_table .header_image_row td img {
    height: auto;
    width: 100%;
    max-width: 100%;
}
.post_table .gallery_image_row td img {
    height: auto;
    width: 100%;
    max-width: 100%;
}
.post_table .meta_row td {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
}
.post_table .meta_row td .title {
    padding-bottom: 5px;
    font-size: 3.5em;
    color: #16121C;
    font-weight: 900;
    line-height: 1em;
    margin: 0;
}
.post_table .meta_row td .subtitle {
    font-size: 1.75em;
    color: #708094;
    line-height: 1em;
    margin: 0;
    padding-bottom: 15px;
}
.post_table .meta_row td .date {
    font-size: 1em;
    color: #555;
}
.post_table .body p, .post_table .body q {
    line-height: 1.5em;
    margin: 0;
}
.post_table .body q {
    font-style: italic;
}
.post_table .body {
    padding: 50px 0;
}
.post_table .body ul {
    margin-top: -5px;
    margin-left: -30px;
    list-style-type: square;
}
.post_table .body ul li {
    margin: 12px;
}
.post_table .body a {
    color: #5f8cc4;
    font-weight: 600;
}
.share_section {
    text-align: center;
    padding: 1em 0;
    margin-bottom: 50px;
}
.share_section h3 {
    font-size: 24px;
    margin: 20px 0;
    margin-top: 10px;
    font-weight: 900;
    color: #16121C;
}
.share_section p {
    margin-top: 30px;
    font-size: 14px;
    color: #8A8993;
    font-size: 12px;
}
.st-btn {
    background: #000 !important;
    border: none !important;
    border-radius: 50% !important;
    height: 30px !important;
    width: 30px !important;
    line-height: 40px !important;
}
.st-btn img {
    -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
}
@media only screen and (max-width: 800px) {
    .post_table .meta_row td {
        text-align: center;
    }
    .post_table .meta_row td .title {
        font-size: 2em;
    }
    .post_table .meta_row td .subtitle {
        font-size: 1em;
    }
    .post_table .meta_row td .date {
        font-size: 0.75em;
    }
}
@media only screen and (max-width: 600px) {
    .post_table .header_row td .float_left .SIFI_desc {
        display: none;
    }
}
.dashboard_posts {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}
.dashboard_post {
    display: inline-block;
    width: 90%;
    max-width: 500px;
    margin: 10px 0;
    padding: 5px;
    padding-left: 15px;
    background-color: #fff;
    color: #16121C;
    text-align: left;
    border-radius: 5px;
    border: 1px solid #D8D8D8;
    box-shadow: 0 10px 10px rgba(0,0,0,0.05);
}
.dashboard_post_meta {
    display: inline-block;
}
.dashboard_post_title {
    margin: 0;
    margin-top: 5px;
    font-size: 20px;
    font-weight: bold;
}
.dashboard_post_subtitle {
    font-size: 12px;
    color: #708094;
    font-weight: 600;
}
.dashboard_post_id {
    font-size: 10px;
    color: #000;
}
.dashboard_post_date {
    color: #BBB;
    font-size: 10px;
}
.dashboard_post_links {
    display: inline-block;
    float: right;
}
.dashboard_post_link {
    margin: 10px;
    display: inline-block;
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 5px;
    color: #FFF;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 12px;
    letter-spacing: 2px;
    font-weight: 900;
    box-shadow: 0 0 10px rgba(0,0,0,0.15);
}
#view_link {
    background-color: #58BE8D;
    border-radius: 5px;
    border: 1px solid #000;
    box-shadow: 0 10px 10px rgba(0,0,0,0.05);
}
#edit_link {
    background-color: #EBCF43;
    border-radius: 5px;
    border: 1px solid #000;
    box-shadow: 0 10px 10px rgba(0,0,0,0.05);
}
#delete_link {
    background-color: #EB5F43;
    border-radius: 5px;
    border: 1px solid #000;
    box-shadow: 0 10px 10px rgba(0,0,0,0.05);
}
.dashboard_post_link {
    margin: 10px;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
    color: #FFF;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 12px;
    letter-spacing: 2px;
    font-weight: 900;
    box-shadow: 0 0 10px rgba(0,0,0,0.15);
}
.input-checkbox {
    display: block;  
    margin-top: 30px;
    margin-bottom: 50px;
    z-index: 999;
}
.dashboard_checkbox {
    opacity: 1 !important;
    padding: 1em;
    height: 20px;
    width: 20px;
    position: relative;
    z-index: 999;
    cursor: pointer;
    pointer-events: all !important;
}
.dashboard_checkbox:focus {
    background-color: #58BE8D;
}
#published {
    position: relative;
    display: inline;
    line-height: 20px;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
}
.dashboard_checkbox:checked {
    background: #58BE8D;
}
.signin_container {
    height: 100vh;
    width: 100vw;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: #F5F8F9;
}

.signin_container form {
    border-radius: 5px;
    border: 1px solid #000;
    box-shadow: 0 10px 10px rgba(0,0,0,0.05);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: #FFF;
}
.signin_container form h5 {
    font-size: 30px;
}

.signin_container form .input_field {
    width: 300px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}


.signin_container form .input_field input {
    width: 200px;
    margin: 10px 0px;
    font-size: 20px;
    padding: 10px 5px;
    border-radius: 5px;
    border: 1px solid #000;
    box-shadow: 0 10px 10px rgba(0,0,0,0.05);
}
.signin_container form .input_field button {
    width: 210px;
    font-size: 20px;
    padding: 10px 5px;
    border-radius: 5px;
    border: 1px solid #000;
    box-shadow: 0 10px 10px rgba(0,0,0,0.05);
    font-weight: 900;
    color: #FFF;
}
.about_wrapper {
}

.about {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
}
.about img {
    max-height: 300px;
    padding-bottom: 40px;
}

.about p {
    width: 80%;
    font-size: 1.5em;
    max-width: 800px;
    line-height: 1.5em;
}

.about a {
    color: #000;
    font-weight: 600;
}
.contact {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 200px;
    font-size: 1.75em;
    color: #8A8993;
}

.contact a {
    color: #000;
    font-weight: 600;
}
