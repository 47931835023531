.about_wrapper {
}

.about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.about img {
    max-height: 300px;
    padding-bottom: 40px;
}

.about p {
    width: 80%;
    font-size: 1.5em;
    max-width: 800px;
    line-height: 1.5em;
}

.about a {
    color: #000;
    font-weight: 600;
}